import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import {
  WaitingDialog,
  SnackAlert,
  GenreItem
} from "components";
import {
  TAB_RANKINGS,
} from "constants/types";
import { getGenres } from "services/genreServices";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 180,
    // top: "180px",
    // [theme.breakpoints.up("sm")]: {
    //   top: "180px",
    // },
    padding: theme.spacing(2),
  },
  title_div: {
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: "Arial",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  description_div: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  description: {
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "18px",
    color: theme.palette.text.primary,
  },
  item: {
    margin: theme.spacing(2),
  },
});

class Genres extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickGenre = this.handleClickGenre.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
  }

  componentDidMount = () => {
    if (this.checkOnline()) {
        this.initialize();
    } else {
      this.props.setError("Internet disconnected! Check your internet connection.");
    }
    window.scrollTo(0,0);
  }

  setError = (message) => {
    this.props.setError(message);
    this.props.requestDataFinished();
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  checkOnline = () => {
    return window.navigator.onLine ? true : false;
  }

  initialize = async () => {
    // this.setWaiting(true);

    // Get Genres
    try {
      const genres = await getGenres();
      this.props.setGenres(genres);
    } catch(err) {
      this.setError(err.message);
    }
    
    // this.setWaiting(false);
  }

  _getAuthToken = async () => {
    const { authUser } = this.props;
    let token = authUser.token;
    return token;
  }

  handleCloseError = () => {
    this.props.clearError();
  }

  handleClickGenre = (genre) => {
    console.log("genre clicked :", genre);
    this.props.selectGenre(genre);
    this.props.setSearchKey("");
    this.props.setArtists([]);
    this.props.setPageNo(0);

    this.props.setCurrentTab(TAB_RANKINGS);

    const route = `/genres/${genre.slug}/rankings`;
    console.log("route :", route);
    this.props.history.push(route);
  }

  render() {
    const {
      classes,
      // theme_mode,
      requesting,
      genres,
      error,
      errorMsg,
    } = this.props;

    // layout variables
    // const width = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    // const isDesktop = width > MAX_WINDOW_WIDTH;
    // const isTablet =
    //   width >= MIN_TABLET_WIDTH &&
    //   width <= MAX_WINDOW_WIDTH;
    // const isMobile = width < MIN_TABLET_WIDTH;

    if (genres.length === 0) {
      return <div></div>;
    }

    return (
      <div className={classes.root}>
        <div className={classes.maincontainer}>
          <div className={classes.title_div}>
            <Typography className={classes.title}>
              {"Streaming Analytics"}
            </Typography>
            <Typography className={classes.title}>
              {"for every artist"}
            </Typography>
          </div>
          <div className={classes.description_div}>
            <Typography className={classes.description}>
              {"Music Futures tracks your favorite artists streaming numbers on Spotify!"}
            </Typography>
            <Typography className={classes.description}>
              {"Get a deeper understanding of artist growth and trends in the music industry."}
            </Typography>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            {genres.map((genre, index) => (
              <Grid item 
                className={classes.item} 
                key={`genre-item-${index}`}
                onClick={e => this.handleClickGenre(genre)}
              >
                <GenreItem 
                  title={genre.name}
                  image={genre.images[0]["url"]}
                  selected={true}
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <WaitingDialog open={requesting} />
        {error && (
          <SnackAlert
            variant={"error"}
            message={errorMsg}
            openStats={error}
            vertical={"top"}
            horizontal={"center"}
            handleEvent={this.handleCloseError}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requesting: state.uiState.requesting,
  error: state.uiState.error,
  errorMsg: state.uiState.errorMsg,
  genres: state.dataState.genres,
  selected_genre: state.dataState.selected_genre
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Genres);
