import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as ROUTES from 'constants/routes';
import { MAX_WINDOW_WIDTH } from 'constants/types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 1100,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    left: 0,
    top: 0,
    backgroundColor: theme.palette.background.main,
    borderLeft: '1px solid #C0C0C0',
    borderRight: '1px solid #C0C0C0',
  },
  logo: {
    margin: 10,
    width: 32,
    height: 32
  },
  title: {
    flexGrow: 1,
    display: 'inline',
    fontFamily: 'Merriweather',
    fontSize: 22,
    fontWeight: 700,
    color: '#5ED1AA'
  },  
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to={ROUTES.LANDING}>
            <img
              alt="Logo"
              className={classes.logo}
              src="/static/images/logo/logo.png"
            />
          </RouterLink>
          <Typography 
            className={classes.title} 
            variant="h6"
          >
            MusicFutures
          </Typography>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
