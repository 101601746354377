import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PeopleIcon from '@material-ui/icons/People';
import CategoryIcon from '@material-ui/icons/Category';

// import { SignOutButton } from 'components';
import { SidebarNav } from './components';
import * as ROUTES from 'constants/routes';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 200,
    [theme.breakpoints.up('md')]: {
      marginTop: 64
      // height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    padding: theme.spacing(1)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));


const Sidebar = props => {
  const { open, variant, onClose, className } = props;

  const classes = useStyles();

  // if (authUser === null || authUser.uid === "") {
  //   return <div></div>;
  // }

  const rankings_pages = [
    {
      title: 'Rankings',
      href: ROUTES.RANKINGS,
      enabled: true,
      icon: <PeopleIcon />
    }
  ];

  const genre_pages = [
    {
      title: 'Genres',
      href: ROUTES.GENRES,
      enabled: true,
      icon: <LibraryBooksIcon />
    }
  ];

  const topsong_pages = [
    {
      title: 'TopSongs',
      href: ROUTES.TOPSONGS,
      enabled: true,
      icon: <LibraryBooksIcon />
    }
  ];

  const topearners_page = [
    {
      title: 'TopEarners',
      href: ROUTES.TOPEARNERS,
      enabled: true,
      icon: <CategoryIcon />
    }
  ];

  // const signin_pages = [
  //   {
  //     title: 'Authentication',
  //     href: ROUTES.SIGN_IN,
  //     enabled: true,
  //     icon: <LockOpenIcon />
  //   }
  // ];


  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div className={clsx(classes.root, className)}>

        <SidebarNav className={classes.nav} pages={rankings_pages} />
        {/* <Divider className={classes.divider} /> */}

        <SidebarNav className={classes.nav} pages={genre_pages} />
        {/* <Divider className={classes.divider} /> */}

        <SidebarNav className={classes.nav} pages={topsong_pages} />
        {/* <Divider className={classes.divider} /> */}

        <SidebarNav className={classes.nav} pages={topearners_page} />
        {/* <Divider className={classes.divider} /> */}

        {/* {authUser ? (
          <div>
            <SidebarNav className={classes.nav} pages={settings_pages} />
            <SignOutButton />
          </div>
        ) : (
          <SidebarNav className={classes.nav} pages={signin_pages} />
        )} */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  // authUser: state.sessionState.authUser
});

export default connect(mapStateToProps)(Sidebar);
