import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { 
  ArtistTable,
  WaitingDialog,
  SnackAlert
} from "components";
import { getGenres } from "services/genreServices";
import { TAB_RANKINGS } from "constants/types";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 180,
    // [theme.breakpoints.up("sm")]: {
    //   top: "180px",
    // },
    padding: theme.spacing(2),
  },
  item: {
    margin: theme.spacing(2),
  },
});

class Rankings extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickArtist = this.handleClickArtist.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
  }

  componentDidMount = async () => {
    window.scrollTo(0,0);

    const { genres, match } = this.props;
    const genre_slug = match.params.genre_slug;

    // Get Genres
    if (genres.length === 0) {
      try {
        const genres = await getGenres();

        if (genre_slug !== undefined) {
          const genre2select = genres.find(genre => genre.slug === genre_slug);
          if (genre2select !== undefined) {
            this.props.selectGenre(genre2select);
          }
        }

        this.props.setGenres(genres);
        this.props.setCurrentTab(TAB_RANKINGS);    
      } catch(err) {
        this.setError(err.message);
      }
    }
  }

  setError = (message) => {
    this.props.setError(message);
    this.props.requestDataFinished();
  }

  handleCloseError = () => {
    this.props.clearError();
  }

  handleClickArtist = (artist_slug) => {
    const { selected_genre } = this.props;

    let route = `/artist/${artist_slug}`;
    if (selected_genre !== null) {
      route = `/genres/${selected_genre.slug}/artist/${artist_slug}`;
    }

    this.props.history.push(route);
  }
  
  render() {
    const { classes, requesting, error, errorMsg, genres, selected_genre } = this.props;

    const genre_slug = this.props.match.params.genre_slug;
    if (genres.length === 0 || (genre_slug !== undefined && selected_genre === null)) {
      return <div></div>;
    }

    return (
      <div className={classes.root}>
        <div className={classes.maincontainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item className={classes.item}>
              <ArtistTable
                filter={"ranking"}
                onClickArtist={this.handleClickArtist}
              />
            </Grid>
          </Grid>
        </div>
        <WaitingDialog open={requesting} />
        {error && (
          <SnackAlert
            variant={"error"}
            message={errorMsg}
            openStats={error}
            vertical={"top"}
            horizontal={"center"}
            handleEvent={this.handleCloseError}
          />
        )}
      </div>
    );
  }
}

Rankings.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  requesting: state.uiState.requesting,
  error: state.uiState.error,
  errorMsg: state.uiState.errorMsg,
  genres: state.dataState.genres,
  selected_genre: state.dataState.selected_genre
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Rankings);
