import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";

const styles = theme => ({
  card: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    cursor: "pointer",
  },
  content: {
    flex: "1 0 auto",
    padding: 0,
    "&:last-child": {
      padding: 0
    },
    backgroundColor: theme.palette.background.default,
  },
  content_nonselected: {
    flex: "1 0 auto",
    padding: 0,
    "&:last-child": {
      padding: 0
    },
    backgroundColor: theme.palette.background.default,
    opacity: 0.3,
  },
  media: {
    display: "flex",
    width: 192,
    height: 192,
    borderRadius: 2,
    backgroundSize: "100%",
  },
  title_div: {
    position: "absolute",
    textAlign: "center",
    top: 128,
    width: 192,
    marginLeft: 'auto',
    marginRight: 'auto'
    // width: 84,
  },
  title: {
    fontFamily: "Arial",
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: 600,
    color: "white",
    // textShadow: "0 0 1px #fff, 0 0 2px #fff, 0 0 2px #fff",
    transition: ".2s linear",
    "&:hover": {
      color: "white",
      fontSize: "28px",
      lineHeight: "32px",
      textShadow: "0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff",
    }
  }
});

class GenreItem extends React.Component {
  render() {
    const { classes, title, image, selected } = this.props;
    let new_selected = selected;
    if (selected === undefined) {
      new_selected = true;
    }

    let refined_title = title;
    if (title === "Dance/Electronic") {
      refined_title = "Dance Electronic";
    }

    return (
      <Card className={classes.card}>
        <CardContent className={new_selected ? classes.content : classes.content_nonselected}>
          <div style={{ position: "relative" }}>
            <CardMedia
              component="img"
              className={classes.media}
              image={image}
            />
            <div className={classes.title_div}>
              <Typography className={classes.title}>
                {refined_title}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(GenreItem);
