import axios from 'axios';
import { LOCAL_DEBUG } from 'constants/types';

let SERVER_URL = "https://musicfuturesapi.hoyack.com";
if (LOCAL_DEBUG) {
  SERVER_URL = "http://localhost:5000";
}

export const getArtistCount = async () => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/count`;
    const response = await axios.get(url);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
}


export const getArtists = async (filter = "", pagesize = 20, offset = 0) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/?filter=${filter}&pagesize=${pagesize}&offset=${offset}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getArtistsByName = async (name) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/search/?name=${name}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getArtistById = async (id) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/${id}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};

export const getArtistBySlug = async (slug) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/slug/${slug}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};

export const getArtistRankingByFollowers = async (followers) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/ranking_f/?followers=${followers}`;
    const response = await axios.get(url);
    console.log("response :", response);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getArtistRankingByTopsong = async (playcount) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/ranking_t/?playcount=${playcount}`;
    const response = await axios.get(url);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getArtistRankingByCountry = async (country, listeners) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/ranking_c/?country=${country}&listeners=${listeners}`;
    const response = await axios.get(url);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};
