import React from 'react';
import { connect } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';
// import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
// import MenuIcon from '@material-ui/icons/Menu';
// import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import { MainAppBar } from 'components';
// import * as ROUTES from 'constants/routes';


const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    // width: MAX_WINDOW_WIDTH,
    // maxWidth: '100%',
    // margin: '0 auto',
    // backgroundColor: theme.palette.background.main,
    // borderLeft: '1px solid #C0C0C0',
    // borderRight: '1px solid #C0C0C0',
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {

  // const { className, onSidebarOpen, navlinks } = props;
  const classes = useStyles();

  const handleClickLogo = () => {

  }

  const handleSearch = () => {

  }

  const handleChangeTab = () => {

  }

  return (
    <div className={classes.root}>
        <MainAppBar 
          onClickLogo={handleClickLogo}
          onSearch={handleSearch}
          onChangeTab={handleChangeTab}
        />
        {/* <Hidden mdUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <Navbar navlinks={navlinks} />
        </Hidden> */}
    </div>
  );
}


Topbar.propTypes = {
  className: PropTypes.string,
  // onSidebarOpen: PropTypes.func  
};

const mapStateToProps = (state) => ({
  navlinks: state.uiState.navlinks
});

export default connect(mapStateToProps, null)(Topbar);
