import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { 
  SearchResultTable,
  WaitingDialog,
  SnackAlert 
} from "components";

const styles = (theme) => ({
  root: {
    height: "100%",
    flexGrow: 1,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 160,
    // [theme.breakpoints.up("sm")]: {
    //   top: "180px",
    // },
    margin: theme.spacing(2),
  },
  item: {
    margin: theme.spacing(2),
  },
});

class SearchResult extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickArtist = this.handleClickArtist.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  handleCloseError = () => {
    this.props.clearError();
  }

  handleClickArtist = (artist_slug) => {
    const { selected_genre } = this.props;

    let route = `/artist/${artist_slug}`;
    if (selected_genre !== null) {
      route = `/genres/${selected_genre.slug}/artist/${artist_slug}`;
    }

    this.props.history.push(route);
  }
  
  render() {
    const { classes, requesting, error, errorMsg } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.maincontainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item className={classes.item}>
              <SearchResultTable 
                onClickArtist={this.handleClickArtist}
              />
            </Grid>
          </Grid>
        </div>
        <WaitingDialog open={requesting} />
        {error && (
          <SnackAlert
            variant={"error"}
            message={errorMsg}
            openStats={error}
            vertical={"top"}
            horizontal={"center"}
            handleEvent={this.handleCloseError}
          />
        )}
      </div>
    );
  }
}

SearchResult.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  genres: state.dataState.genres,
  requesting: state.uiState.requesting,
  error: state.uiState.error,
  errorMsg: state.uiState.errorMsg,
  selected_genre: state.dataState.selected_genre
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SearchResult);
