// Session Action Types
export const SIGN_IN_START          = 'SIGN_IN_START';
export const SIGN_IN_FAILURE        = 'SIGN_IN_FAILURE';
export const SIGN_IN_SUCCESS        = 'SIGN_IN_SUCCESS';
export const SIGN_OUT               = 'SIGN_OUT';
export const SIGN_UP_START          = 'SIGN_UP_START';
export const SIGN_UP_FAILURE        = 'SIGN_UP_FAILURE';

// Socket Action Types
export const SOCKET_CONNECT         = 'SOCKET_CONNECT';
export const SOCKET_DISCONNECT      = 'SOCKET_DISCONNECT';

// Data Action Types
export const SET_GENRES             = 'SET_CATEGORIES';
export const SELECT_GENRE           = 'SET_CURRENT_GENRE';
export const SET_ARTISTS            = 'SET_ARTISTS';
export const SELECT_ARTIST          = 'SELECT_ARTIST';
export const SET_ARTIST_COUNT       = 'SET_ARTIST_COUNT';

export const SET_SEARCHKEY          = 'SET_SEARCHKEY';
export const SET_SEARCHRESULT       = 'SET_SEARCHRESULT';

// UI Action Types
export const SELECT_THEME_MODE      = 'SELECT_THEME_MODE';
export const SHOW_SPLASH_SCREEN     = 'SHOW_SPLASH_SCREEN';
export const REQUEST_DATA_PENDING   = 'REQUEST_DATA_PENDING';
export const REQUEST_DATA_FINISHED  = 'REQUEST_DATA_FINISHED';

export const SET_ERROR              = 'SET_ERROR';
export const CLEAR_ERROR            = 'CLEAR_ERROR';

export const SET_CURRENT_TAB        = 'SET_CURRENT_TAB';
export const SET_PAGE_NO            = 'SET_PAGE_NO';
export const SET_PAGE_SIZE          = 'SET_PAGE_SIZE';

export const LOGIN_BACKROUTE        = 'LOGIN_BACKROUTE';
