import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Genres as GenresView,
  Rankings as RankingsView,
  TopSongs as TopSongsView,
  TopEarners as TopEarnersView,
  SearchResult as SearchResultView,
  Artist as ArtistView,
  SignIn as SignInView,
  SignUp as SignUpView,
  ResetPassword as ResetPasswordView,
  NotFound as NotFoundView,
} from "./views";


const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect
          exact
          from={ROUTES.LANDING}
          to={ROUTES.GENRES}
        />
        <RouteWithLayout
          component={GenresView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRES}
        />
        <RouteWithLayout
          component={RankingsView}
          exact
          layout={MainLayout}
          path={ROUTES.RANKINGS}
        />
        <RouteWithLayout
          component={RankingsView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRE_RANKINGS}
        />
        <RouteWithLayout
          component={TopSongsView}
          exact
          layout={MainLayout}
          path={ROUTES.TOPSONGS}
        />
        <RouteWithLayout
          component={TopSongsView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRE_TOPSONGS}
        />
        <RouteWithLayout
          component={TopEarnersView}
          exact
          layout={MainLayout}
          path={ROUTES.TOPEARNERS}
        />
        <RouteWithLayout
          component={TopEarnersView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRE_TOPEARNERS}
        />
        <RouteWithLayout
          component={SearchResultView}
          exact
          layout={MainLayout}
          path={ROUTES.SEARCHRESULTS}
        />
        <RouteWithLayout
          component={ArtistView}
          exact
          layout={MainLayout}
          path={ROUTES.ARTIST}
        />
        <RouteWithLayout
          component={ArtistView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRE_ARTIST}
        />
        <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path={ROUTES.SIGN_UP}
        />
        <RouteWithLayout
          component={SignInView}
          exact
          layout={MinimalLayout}
          path={ROUTES.SIGN_IN}
        />
        <RouteWithLayout
          component={ResetPasswordView}
          exact
          layout={MinimalLayout}
          path={ROUTES.RESET_PASSWORD}
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path={ROUTES.NOT_FOUND}
        />
        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
