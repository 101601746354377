import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
// import { useMediaQuery } from '@material-ui/core';
import { Topbar, Footer } from './components';
import { MAX_WINDOW_WIDTH } from 'constants/types';

const useStyles = makeStyles(theme => ({
  // root: {
  //   paddingTop: 56,
  //   [theme.breakpoints.up('md')]: {
  //     paddingTop: 64
  //   },
  //   height: '100%'
  // },
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.main,
    borderLeft: '1px solid #C0C0C0',
    borderRight: '1px solid #C0C0C0',
  },
  shiftContent: {
    paddingLeft: 200,
    backgroundColor: theme.palette.background.default
  },
  content: {
    backgroundColor: theme.palette.background.default
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  // const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
  //   defaultMatches: true
  // });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  // const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        // [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      {/* <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      /> */}
      <div className={classes.content}>
        {children}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
