export const get_timestring = (timestamp) => {
  var a = new Date(timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();

  var timeText = "";
  var now_timestamp = Math.floor((new Date()).getTime() / 1000);

  // consider timezone
  var diff = now_timestamp - timestamp;
  if (diff > 24 * 3600) {
    timeText = month + " " + date + ", " + year;
  } else {
    timeText = Math.floor(diff / 3600) + "h ago";
  }
  return timeText;
};

export const get_elapsed_time = (datetime) => {
  let timestamp = new Date(datetime);
  const now = new Date();
  const timeDiff = Math.round((now - timestamp) / 1000);

  let result = 0;
  if (timeDiff < 3600) {
    result = Math.round(timeDiff / 60);
    return `${result}m ago`;
  }
  if (timeDiff < 86400) {
    result = Math.round(timeDiff / 3600);
    return `${result}h ago`;
  }

  result = Math.round(timeDiff / 86400);
  return `${result}d ago`;
};

export const get_today_string = () => {
  var today = new Date();
  var year = today.getUTCFullYear();
  var month = today.getUTCMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var date = today.getUTCDate();
  if (date < 10) {
    date = "0" + date;
  }
  return year + "-" + month + "-" + date;
};


function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

/*
    Get K-format string.
    Input   : int value
    Return  : K-format string if the value >= 1000, otherwise value string
*/
export const int2kstring = (value) => {
  var kstring = "";
  if (value < 1000) {
    kstring = (~~value).toString();
  } else if (value < 1000000) {
    value /= 1000;
    value = round(value, 1);
    kstring = value.toString() + "K";
  } else {
    value /= 1000000;
    value = round(value, 1);
    kstring = value.toString() + "M";
  }

  return kstring;
};

export const int2commastring = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/*
*/
export const gen_random_int = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}

/*
    Get sub string of instr between startstr and endstr.
*/
export const get_substring = (instr, startstr, endstr) => {
  if (startstr === "") {
    const length = instr.indexOf(endstr);
    if (length >= 0) return instr.substring(0, length);
  }

  let start_idx = instr.indexOf(startstr);
  if (start_idx === -1) return "";

  start_idx += startstr.length;
  if (start_idx >= 0) {
    if (endstr !== undefined) {
      const length = instr.substring(start_idx).indexOf(endstr);
      if (length === -1) return instr.substring(start_idx);
      return instr.substring(start_idx, start_idx + length);
    }
    return instr.substring(start_idx);
  }
};

export const is_valid_url = (string) => {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
  return (res !== null)
};

export const get_urls = (string) => {
  var matches = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
  return matches;
}

export const get_hostname = (url) => {
  var urlParts = url.replace('http://','').replace('https://','').split(/[/?#]/);
  return urlParts[0];
}

export const get_domainname = (url) => {
  var urlParts = url.replace('http://','').replace('https://','').replace('www.','').split(/[/?#]/);
  return urlParts[0];
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}