import * as ActionTypes from "actions/ActionTypes";
import {
  THEME_MODE_LIGHT,
  TAB_GENRES
} from "constants/types";


const initialState = {
  theme_mode: THEME_MODE_LIGHT,
  show_splash: true,
  requesting: false,
  error: false,
  errorMsg: "",
  currentTab: TAB_GENRES,
  page_no: 0,
  page_size: 25
};


function uiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SELECT_THEME_MODE: {
      return {
        ...state,
        theme_mode: action.mode
      };
    }

    case ActionTypes.SHOW_SPLASH_SCREEN: {
      return {
        ...state,
        show_splash: action.now
      };
    }

    case ActionTypes.REQUEST_DATA_PENDING: {
      return {
        ...state,
        requesting: true
      };
    }

    case ActionTypes.REQUEST_DATA_FINISHED: {
      return {
        ...state,
        requesting: false
      };
    }

    case ActionTypes.SET_ERROR: {
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg
      };
    }

    case ActionTypes.CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMsg: ""
      };
    }

    case ActionTypes.SET_CURRENT_TAB: {
      return {
        ...state,
        currentTab: action.tabValue
      };
    }

    case ActionTypes.SET_PAGE_NO: {
      return {
        ...state,
        page_no: action.pageNo
      };
    }

    case ActionTypes.SET_PAGE_SIZE: {
      return {
        ...state,
        page_size: action.pagesize
      };
    }

    default:
      return state;
  }
}

export default uiReducer;
