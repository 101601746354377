import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { ArtistCard } from "components";
import { numberWithCommas } from 'utility/utils';
import {
  getArtistRankingByFollowers,
  getArtistRankingByTopsong,
  getArtistRankingByCountry
} from "services/artistServices";

const styles = (theme) => ({
  root: {
    position: 'relative',
  },
  cover_container: {
    position: 'relative',
    marginBottom: 2,
    backgroundImage: "linear-gradient(#05050B 100%, #111118 30%)",
    height: 144,
  },
  cover: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 'auto',
    height: 144,
    maskImage: "linear-gradient(to right, transparent 0%, black 30%, black 80%, transparent 100%)",
    webkitMaskImage: "linear-gradient(to right, transparent 0%, black 30%, black 80%, transparent 100%)",
  },
  social_container: {
    position: 'absolute',
    top: 110,
    left: 200,
  },
  social_icon: {
    display: 'inline',
    cursor: 'pointer',
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2),
  },
  avatar_container: {
    position: 'absolute',
    top: 100,
    left: 40,
  },
  avatar: {
    width: 144,
    height: 144,
  },
  name: {
    position: 'absolute',
    top: 32,
    left: 132,
    fontFamily: 'system-ui',
    fontSize: '48px',
    lineHeight: '52px',
    letterSpacing: '-2px',
    fontWeight: 700,
    color: 'white',
    textTransform: 'uppercase',
  },
  url: {
    position: 'absolute',
    top: 80,
    left: 288,
    fontFamily: 'system-ui',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.5px',
    fontWeight: 700,
    color: 'white',
    textTransform: 'uppercase',
  },
  info_container: {
    marginBottom: theme.spacing(2),
    border: '1px solid black',
    height: 112,
    width: '100%',
  },
  info_content: {
    margin: theme.spacing(3),
    marginLeft: 220,
  },
  infoItem: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    textAlign: 'center'
  },
  title: {
    fontFamily: 'Arial',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    marginBottom: theme.spacing(1),
  },
  data: {
    fontFamily: 'Arial',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0px',
    marginTop: theme.spacing(1),
  },
  rank_container: {
    marginBottom: theme.spacing(1),
    border: '1px solid black',
    height: 156,
    width: '100%',
  },
  rank_content: {
    margin: theme.spacing(3),
  },
  rankItem: {
    borderRight: '1px solid black',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    vericalAlign: 'middle',
  },
  rankLastItem: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    vericalAlign: 'middle',
  },
  rank: {
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: '#81D452', 
  },
  monthly_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: 56,
    border: '1px solid black',
    height: 144,
    textAlign: 'center',
  },
  annual_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingTop: 56,
    border: '1px solid black',
    height: 144,
    textAlign: 'center',
    vericalAlign: 'middle',
  },
  bio_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid black',
  },
  fan_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  fantitle: {
    fontFamily: 'Arial',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    color: theme.palette.text.primary, 
  },
  card: {
    margin: theme.spacing(1),
  }
});

class ArtistContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ranking_followers: 0,
      ranking_toptrack: 0,
      ranking_country: 0
    }

    this.handleClickSocial = this.handleClickSocial.bind(this);
  }

  componentDidMount = async () => {
    const { artist } = this.props; 
    const ranking_f = await getArtistRankingByFollowers(artist.followers);
    const ranking_t = await getArtistRankingByTopsong(artist.topsongPlaycount);
    const ranking_c = await getArtistRankingByCountry(artist.country, artist.countryListeners);
    this.setState({
      ...this.state,
      ranking_followers: ranking_f,
      ranking_toptrack: ranking_t,
      ranking_country: ranking_c
    });
  }

  componentDidUpdate = async (prevProps) => {
    const { artist } = this.props; 
    if (prevProps.artist.id !== artist.id) {
      const ranking_f = await getArtistRankingByFollowers(artist.followers);
      const ranking_t = await getArtistRankingByTopsong(artist.topsongPlaycount);
      const ranking_c = await getArtistRankingByCountry(artist.country, artist.countryListeners);
      this.setState({
        ...this.state,
        ranking_followers: ranking_f,
        ranking_toptrack: ranking_t,
        ranking_country: ranking_c
      });
    }
  }

  handleClickSocial = (url) => {
    window.open(url, '_blank').focus();
  }

  debutDate = (artist) => {
    const albums = artist.albums;
    if (albums === null || albums.length === 0) {
      return null;
    }

    let debut_ts = Date.now();
    for (let album of albums) {
      for (let item of album.releases.items) {
        let album_ts = Date.parse(item.date.isoString);
        if (album_ts < debut_ts) {
          debut_ts = album_ts;
        }
      }
    }

    const debut_date = new Date(debut_ts);

    const options = {
      year: 'numeric', month: 'long', day: 'numeric'
    };
    
    var fmt = new Intl.DateTimeFormat('en-US', options);
    const debut_datestr = fmt.format(debut_date);
    // const debut_datestr = `${debut_date.getFullYear()}-${debut_date.getMonth() + 1}-${debut_date.getDate()}`;
    return debut_datestr;
  }
  
  render() {
    const { classes, artist, onClickArtist } = this.props;
    const { ranking_followers, ranking_toptrack, ranking_country } = this.state;

    const monthlyEarnings = artist.monthlyListeners * 0.012; // 0.004 * 3
    const annualEarnings = monthlyEarnings * 12;

    const externalLinks = artist.externalLinks;
    let facebook = "";
    let instagram = "";
    let twitter = "";
    // let wikipedia = "";
    if (externalLinks !== null) {
      for (let link of externalLinks) {
        if (link.name === "FACEBOOK") {
          facebook = link.url;
        } else if (link.name === "INSTAGRAM") {
          instagram = link.url;
        } else if (link.name === "TWITTER") {
          twitter = link.url;
        }
        // } else if (link.name === "WIKIPEDIA") {
        //   wikipedia = link.url;
        // }
      }
    }

    const rankString = (rank) => {
      if (rank === 0) {
        return "---";
      } else if (rank === 1) {
        return "1st";
      } else if (rank === 2) {
        return "2nd";
      } else {
        return `${rank}th`;
      }
    }

    const debut_datestring = this.debutDate(artist);

    return (
      <div className={classes.root}>
        <div className={classes.cover_container}>
          {artist.headerImage && 
            <img className={classes.cover} src={artist.headerImage} alt={artist.id} />
          }
          <Typography className={classes.name}>
            {artist.name}
          </Typography>
          {/* <Typography className={classes.url}>
            {"firstcovers.com"}
          </Typography> */}
        </div>
        <div className={classes.social_container}>
          {facebook !== "" &&
            <img className={classes.social_icon} 
              src={"/static/images/icons/light/facebook.png"} 
              alt={"facebook"}
              onClick={e => this.handleClickSocial(facebook)} 
            />
          }
          {instagram !== "" &&
            <img className={classes.social_icon} 
              src={"/static/images/icons/light/instagram.png"} 
              alt={"instagram"}
              onClick={e => this.handleClickSocial(instagram)} 
            />
          }
          {twitter !== "" &&
            <img className={classes.social_icon} 
              src={"/static/images/icons/light/twitter.png"} 
              alt={"twitter"}
              onClick={e => this.handleClickSocial(twitter)} 
            />
          }
        </div>
        <div className={classes.avatar_container}>
          <img 
            className={classes.avatar} 
            src={artist.avatarImage ? artist.avatarImage : "/static/images/blank_avatar.png"} 
            alt={artist.name} 
          />
        </div>

        <div className={classes.info_container}>
          <div className={classes.info_content}>
            <Grid container>
              <Grid item className={classes.infoItem}>
                <Typography className={classes.title}>
                  Monthly Listeners
                </Typography>
                <Typography className={classes.data}>
                  {numberWithCommas(artist.monthlyListeners)}
                </Typography>
              </Grid>

              <Grid item className={classes.infoItem}>
                <Typography className={classes.title}>
                  Global Rank
                </Typography>
                <Typography className={classes.data}>
                  {artist.worldRank}
                </Typography>
              </Grid>

              <Grid item className={classes.infoItem}>
                <Typography className={classes.title}>
                  Followers
                </Typography>
                <Typography className={classes.data}>
                  {numberWithCommas(artist.followers)}
                </Typography>
              </Grid>

              <Grid item className={classes.infoItem}>
                <Typography className={classes.title}>
                  Country
                </Typography>
                <Typography className={classes.data}>
                  {artist.country || "---"}
                </Typography>
              </Grid>

              <Grid item className={classes.infoItem}>
                <Typography className={classes.title}>
                  Artist Debut
                </Typography>
                <Typography className={classes.data}>
                  {debut_datestring === null ? "---" : debut_datestring}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.rank_container}>
          <div className={classes.rank_content}>
            <Grid container>
              <Grid item className={classes.rankItem} xs={3}>
                <Typography className={classes.rank}>
                  {artist.worldRank === 0 ? "-" : rankString(artist.worldRank)}
                </Typography>
                <Typography className={classes.data}>
                  {"Monthly Listeners Rank"}
                </Typography>
              </Grid>

              <Grid item className={classes.rankItem} xs={3}>
                <Typography className={classes.rank}>
                  {rankString(ranking_followers)}
                </Typography>
                <Typography className={classes.data}>
                  {"Followers Rank"}
                </Typography>
              </Grid>

              <Grid item className={classes.rankItem} xs={3}>
                <Typography className={classes.rank}>
                  {rankString(ranking_toptrack)}
                </Typography>
                <Typography className={classes.data}>
                  {"Top Song Rank"}
                </Typography>
              </Grid>

              <Grid item className={classes.rankLastItem} xs={3}>
                <Typography className={classes.rank}>
                  {rankString(ranking_country)}
                </Typography>
                <Typography className={classes.data}>
                  {"Country Rank"}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.monthly_container}>
              <Typography className={classes.rank}>
                $ {numberWithCommas(Math.floor(monthlyEarnings))}
              </Typography>
              <Typography className={classes.data}>
                {"Estimated Monthly Earnings"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.annual_container}>
              <Typography className={classes.rank}>
                $ {numberWithCommas(Math.floor(annualEarnings))}
              </Typography>
              <Typography className={classes.data}>
                {"Estimated Annual Earnings"}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <div className={classes.bio_container}>
          <div
            style={{fontFamily: "Roboto, sans-serif", fontWeight: 400, fontSize: "14px", lineHeight: "18px"}}
            dangerouslySetInnerHTML={{ __html: artist.biography }}
          />
        </div>
        {artist.relatedArtists && 
          <div className={classes.fan_container}>
            <Typography className={classes.fantitle}>
              {"Fans also like"}
            </Typography>
            <Grid container>
              {artist.relatedArtists.map(relatedArtist => 
                <Grid item className={classes.card}>
                  <ArtistCard
                    artist={relatedArtist}
                    onClickArtist={onClickArtist}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        }
      </div>
    );
  }
}

ArtistContent.propTypes = {
  className: PropTypes.string,
};


export default withStyles(styles)(ArtistContent);
